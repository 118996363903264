import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavigationWrapper } from '../../components/NavigationWrapper';
import { AppState } from '../../store';
import { EXPERIMENT_APP_URL } from '../../config';

type Props = {
  user: any;
};

const MainPageComponent = (props: Props) => {
  const [url, setUrl] = useState('');
  const { user } = props;

  if (!url && user) {
    setUrl(`https://${EXPERIMENT_APP_URL}/#/signin?token=${props.user.apiToken}&page=groupDealId`);
  }

  return (
    <>
      <NavigationWrapper />
      <iframe
        src={url}
        frameBorder="0"
        title="new campaign"
        style={{
          position: 'fixed',
          top: '4.375rem',
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          zIndex: 10,
        }}
      />
    </>
  );
};

const mapState = (state: AppState) => ({
  user: state.auth.user,
});

export const MainPage = connect(mapState)(MainPageComponent);
