import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { applicationConstants } from './constants';

export interface ApplicationState {
  sidebarOpened: boolean;
  isOpenExchangeMode: boolean;
}

const defaultApplicationState: ApplicationState = {
  sidebarOpened: true,
  isOpenExchangeMode: true,
};

function toggleSidebar(state: ApplicationState): ApplicationState {
  return {
    ...state,
    sidebarOpened: !state.sidebarOpened,
  };
}

function toggleMode(state: ApplicationState, action: Action<boolean>): ApplicationState {
  return {
    ...state,
    isOpenExchangeMode: action.payload,
  }
}

const reducer = reducerFromMap<ApplicationState>(
  defaultApplicationState,
  {
    [applicationConstants.TOGGLE__SIDEBAR_MENU]: toggleSidebar,
    [applicationConstants.TOGGLE__MODE]: toggleMode,
  }
);

export const app = (state: ApplicationState = defaultApplicationState, action: Action<any>) => reducer(state, action);
