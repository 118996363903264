import { createAction } from '../../utils/actions';
import { applicationConstants } from './constants';

export const applicationActions = {
  toggleSidebarMenu() {
    return createAction<void>(applicationConstants.TOGGLE__SIDEBAR_MENU);
  },

  toggleMode(value: boolean) {
    return createAction<boolean>(applicationConstants.TOGGLE__MODE, value);
  },
};

export interface ToggleSidebarMenu {
  toggleSidebarMenu: () => void;
}

export interface ToggleMode {
  toogleMode: (value: boolean) => void;
}
