import { AxiosResponse } from 'axios';

import { getInstance } from './index';
import { Logger } from '../utils/logger';
import { Option } from '../models/Option';

interface GroupResponse {
  app_list: string;
  blacklist: number;
  description?: string;
  group_id: number;
  group_name: string;
  total_apps: number;
  whitelist: number;
};

interface IResponse {
  data: GroupResponse[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  total: any;
};

interface IParams {
  recordsTotal?: number;
  search_field?: string;
  draw?: number;
  no_of_entries?: number;
  pgno?: number;
  sort_by?: string;
  sort_type?: string;
};

interface ICreatingParams {
  name: string;
  app_list?: string;
  description?: string;
}

export const Groups = async ({
  recordsTotal = 1,
  search_field = '',
  draw = 1,
  no_of_entries = 50,
  pgno = 1,
  sort_by = 'group_name',
  sort_type = 'asc',
}: IParams): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<IResponse> = await getInstance().get('/app_groups', {
      params: {
        recordsTotal,
        search_field,
        draw,
        no_of_entries,
        pgno,
        sort_by,
        sort_type,
      },
    });
    return response.data.data.map(i => ({
      label: i.group_name,
      value: i.group_id,
    }));
  } catch (e) {
    Logger.log('Error while get inventory groups:', e);
    return [];
  }
};

export const CreateNewGroup = async ({
  name,
  app_list = '-1',
  description = '',
}: ICreatingParams): Promise<void> => {
  try {
    await getInstance()
      .post('/app_groups', {
        name,
        app_list,
        description,
      });
  } catch (e) {
    Logger.log('Error while create group:', e);
  }
};

export const DeleteGroup = async (id: number) => {
  try {
    await getInstance().delete(`/app_groups/${id}`);
  } catch (e) {
    Logger.log('Error while delete group:', e);
  }
}

