export type Option<T = any> = {
  label: string;
  value: T;
}

export type OptionId<T = any> = Option<T> & {
  id: number;
}

export type OptionData<S, T = any> = Option<T> & {
  options: S;
}

export interface StickedOption<T = any> extends Option<T> {
  alwaysEnabled?: boolean;
}

export const sortingColumnOptions: StickedOption[] = [
  {
    label: 'Publisher',
    value: 'publisher',
    alwaysEnabled: true,
  },
  {
    label: 'Exchange',
    value: 'exchange',
  },
  {
    label: 'IAB Category',
    value: 'category',
  },
  {
    label: 'OS',
    value: 'os',
  },
  {
    label: 'App ID',
    value: 'appId',
  },
  {
    label: 'Traffic Type',
    value: 'trafficType',
  },
];
