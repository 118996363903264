import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SelectWithCreatingBlock } from 'iqm-framework';
import { Switch } from 'factor';

import { AppState } from '../../../store/index'
import { applicationActions, ToggleMode } from '../../../store/app/actions';
import { Option } from '../../../models/Option';
import { API } from '../../../api';

interface Props extends ToggleMode {
  isAuthorized: boolean;
  isOpenExchangeMode: boolean;
}

const NavigationContentCoomponent: React.FC<Props> = props => {
  const [items, setItems] = useState<Option<number>[]>([]);
  const [value, setValue] = useState<Option<number>[]>([]);

  useEffect(() => {
    API.Groups.Groups({})
      .then(res => setItems(res))
  }, [props.isAuthorized]);

  const deleteHandler = (item: Option<number>) => {
    API.Groups.DeleteGroup(item.value)
      .then(() => {
        setItems(prev => prev.filter(i => i.value !== item.value));
        if (value.length && (item.value === value[0].value)) {
          setValue([]);
        }
      })
  };

  const createHandler = (name: string) => {
    API.Groups.CreateNewGroup({ name })
      .then(() => {
        API.Groups.Groups({})
          .then(res => {
            const newGroup = res.find(i => i.label === name);
            setItems(res);
            setValue(newGroup ? [newGroup] : []);
          })
      })
  };

  return (
    <div className="flex-grow-1 justify-content-between mr-2 align-items-center" style={{display: "none"}}>
      <SelectWithCreatingBlock
        newItemPlaceholder="Inventory Group Name"
        items={items}
        value={value}
        onCreate={createHandler}
        onDelete={deleteHandler}
        onClear={() => setValue([])}
        onSelect={(item: Option<number>[]) => setValue(item)}
        buttonLabel="Create Group"
        label="Inventory Group"
        selectedLabel={value.length ? value[0].label : ''}
        placeholder="Select Inventory Group"
      />
      <Switch
        className="_md filter__btn-group"
        list={['Open Exchange', 'Private Dials']}
        name="modeSwitcher"
        value={!props.isOpenExchangeMode}
        onChange={(v: boolean) => {
          props.toogleMode(!v);
        }}
      />
    </div>
  );
}

const mapState = (state: AppState) => ({
  isAuthorized: state.auth.authorized,
  isOpenExchangeMode: state.app.isOpenExchangeMode,
});

const mapAction = {
  toogleMode: applicationActions.toggleMode,
};

export const NavigationContent = connect(mapState, mapAction)(NavigationContentCoomponent);
