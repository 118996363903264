import React from 'react';
import { Header } from 'iqm-framework';

import './styles.scss';
import { NavigationContent } from './NavigationContent';

export class NavigationWrapper extends React.Component {
  handleLogout = () => {
    document.location.reload();
  };

  render() {
    return (
      <Header
        backButton={false}
        title="Inventory Managment"
        leftLogo="Inventory"
        onLogout={this.handleLogout}
      >
        <NavigationContent />
      </Header>
    );
  }
}
