import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { tableConstants } from './constants';
import { StickedOption, sortingColumnOptions } from '../../models/Option';

export interface TableState {
  sortingColumns: StickedOption[];
}

export const defaultTableState: TableState = {
  sortingColumns: sortingColumnOptions,
};

function setSortingColumns(state: TableState, action: Action<StickedOption[]>): TableState {
  return {
    ...state,
    sortingColumns: action.payload,
  };
}

const reducer = reducerFromMap<TableState>(
  defaultTableState,
  {
    [tableConstants.SET_SORTING_COLUMNS]: setSortingColumns,
  }
);

export const table = (state: TableState = defaultTableState, action: Action<any>) => reducer(state, action);
