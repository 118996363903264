import { AxiosResponse } from 'axios';

import { getInstance } from './index';
import { Logger } from '../utils/logger';
import { Option, OptionData } from '../models/Option';
import { GetResponseWithData } from '../models/Response';

interface IResponse {
  name: string;
  id: number;
};

interface CategoriesResponse {
  group: string;
  id: number;
  name: string;
  parent_id: number;
};

interface CountriesResponse extends IResponse {
  alpha2: string;
};

interface AuthUserParams {
  dspId: number;
  advertiserId: number;
};

export const Exchanges = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<IResponse[]> = await getInstance().get('/exchanges');
    return response.data.map(i => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get exchanges:', e);
    return [];
  }
};

export const Categories = async (): Promise<OptionData<Option<number>[], number>[]> => {
  try {
    const response: AxiosResponse<CategoriesResponse[]> = await getInstance().get('/adcategories');
    return response.data.reduce((acc: OptionData<Option<number>[], number>[], item) => {
      const findedParent = acc.find(i => item.parent_id === i.value);

      if (findedParent) {
        findedParent.options.push({ label: item.name, value: item.id });
      } else {
        acc.push({
          label: item.group,
          value: item.parent_id,
          options: [{
            label: item.name,
            value: item.id,
          }]
        })
      }

      return acc;
    }, []);
  } catch (e) {
    Logger.log('Error while get categories:', e);
    return [];
  }
};

export const Countries = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<CountriesResponse[]> = await getInstance().get('/countries');
    return response.data.map(i => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get countries:', e);
    return [];
  }
};

export const CreativeTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<IResponse[]> = await getInstance().get('/creative_types');
    return response.data.map(i => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get creative types:', e);
    return [];
  }
};

export const TrafficTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<IResponse[]> = await getInstance().get('/source_traffic_types');
    return response.data.map(i => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get traffic types:', e);
    return [];
  }
};

export const DeviceTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<IResponse[]> = await getInstance().get('/device_types');
    return response.data.map(i => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get traffic types:', e);
    return [];
  }
};

export const DeviceOS = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<IResponse[]> = await getInstance().get('/device_os');
    return response.data.map(i => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get traffic types:', e);
    return [];
  }
};

export const Languages = async ({ dspId, advertiserId }: AuthUserParams): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<IResponse[]>> = await getInstance()
      .get(`/v2/master/segment/language?dspId=${dspId}&advertiserId=${advertiserId}`);
    return response.data.responseObject.data.map(i => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get languages:', e);
    return [];
  }
}
